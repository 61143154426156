import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default () => {
  const [hydration, setHydration] = React.useState(80);
  const [salt, setSalt] = React.useState(2);
  const [starter, setStarter] = React.useState(20);
  const [flour, setFlour] = React.useState(420);

  const starterWeight = _.round((starter / 100) * flour, 1);
  const saltWeight = _.round((salt / 100) * flour, 1);
  const waterWeight = _.round((hydration / 100) * flour - starterWeight / 2, 1);
  const flourWeight = _.round(flour - starterWeight / 2, 1);

  return (
    <Layout>
      <Helmet>
        <title>Dough Calculator</title>
      </Helmet>
      <h1>Dough Calculator</h1>
      <p>
        Enter your desired hydration, salt percentage, starter percentage, and
        total flour weight in grams. This assumes a 100% hydration starter will
        be used. The calculated values reflect the weights of each ingredient to
        add at the mixing stage.
      </p>
      <div className="columns is-vcentered">
        <div className="column">
          <form>
            <label className="label" htmlFor="hydration">
              Desired Hydration
            </label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  id="hydration"
                  name="hydration"
                  value={hydration}
                  onChange={e => setHydration(e.target.value)}
                />
              </div>
              <div class="control">
                <div class="button is-static">%</div>
              </div>
            </div>
            <label className="label" htmlFor="starter">
              Starter
            </label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  id="starter"
                  name="starter"
                  value={starter}
                  onChange={e => setStarter(e.target.value)}
                />
              </div>
              <div class="control">
                <div class="button is-static">%</div>
              </div>
            </div>
            <label className="label" htmlFor="salt">
              Salt
            </label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  id="salt"
                  name="salt"
                  value={salt}
                  onChange={e => setSalt(e.target.value)}
                />
              </div>
              <div class="control">
                <div class="button is-static">%</div>
              </div>
            </div>
            <label className="label" htmlFor="flour">
              Total Flour
            </label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  id="flour"
                  name="flour"
                  value={flour}
                  onChange={e => setFlour(e.target.value)}
                />
              </div>
              <div class="control">
                <div class="button is-static">g</div>
              </div>
            </div>
          </form>
        </div>
        <div className="column">
          <table className="table is-bordered is-hoverable">
            <tbody>
              <tr>
                <td>
                  <strong>Starter Amount</strong>
                </td>
                <td>{starterWeight} g</td>
              </tr>
              <tr>
                <td>
                  <strong>Salt Amount</strong>
                </td>
                <td>{saltWeight} g</td>
              </tr>
              <tr>
                <td>
                  <strong>Water Amount</strong>
                </td>
                <td>{waterWeight} g</td>
              </tr>
              <tr>
                <td>
                  <strong>Flour Amount</strong>
                </td>
                <td>{flourWeight} g</td>
              </tr>
              <tr>
                <td>
                  <strong>Total Weight</strong>
                </td>
                <td>
                  {starterWeight + saltWeight + waterWeight + flourWeight} g
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};
